import { useState, useRef, useEffect } from "react";
import { atom } from "helux";
import { nanoid } from "nanoid";
let apiPath = process.env.REACT_APP_BASE_IP + process.env.REACT_APP_BASE_API;
if (process.env.NODE_ENV == "production") {
  apiPath = window.location.origin + apiPath;
}
let WAR = "";

// https 环境下，需要将 ws 改为 wss
if (window.location.protocol == "https:") {
  WAR = apiPath.replace("https", "wss");
} else {
  WAR = apiPath.replace("http", "ws");
}
const useWebsocket = ({
  url,
  verify,

  heartbeat = {
    open: true,
    time: 10000,
    msg: "ping",
  },
  bigDataType = [],
  atomDataType = [],
  separateDataType = "",
  separateData2Type = "",
  separateData3Type = "",
}) => {
  // console.log("🚀 ~ file: useWebSocket.js:13 ~ useWebsocket ~ url:", url, verify)

  const ws = useRef(null);
  // socket 数据
  const [wsData, setMessage] = useState({}); // 普通数据
  const [wsBigData, setWsBigData] = useState({}); // 数据量大的数据
  const [wsAtomData, setAtom] = atom({}); // 处理密集型数据
  const [wsSeparateData, setWsSeparateData] = useState({}); //单独处理动作数据
  const [wsSeparateData2, setWsSeparateData2] = useState({});
  const [wsSeparateData3, setWsSeparateData3] = useState({});

  //  socket 状态
  const [readyState, setReadyState] = useState({ key: 1, value: "正在连接中" });
  let heartbeatTimer = null;
  const creatWebSocket = () => {
    // debugger
    const stateArr = [
      { key: 1, value: "正在连接中" },
      { key: 0, value: "已经连接并且正在通讯" },
      { key: 2, value: "连接正在关闭" },
      { key: 3, value: "连接已关闭或者没有连接成功" },
    ];
    try {
      ws.current = new WebSocket(WAR + url);

      ws.current.onopen = () => {
        console.log("WebSocket链接已经建立！");
        // console.log('WebSocket连接成功onopen', ws.current)
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
      };

      ws.current.onclose = () => {
        console.log("WebSocket链接已经关闭！");
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
        clearInterval(heartbeatTimer);
      };

      ws.current.onerror = () => {
        // console.log('WebSocket连接成功onerror', ws.current)
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
        clearInterval(heartbeatTimer);
      };

      if (heartbeat.open) {
        // 心跳检测重连
        heartbeatTimer = setInterval(function () {
          // console.log("🚀 ~ file: useWebsocket.js:52 ~ heartbeatTimer:", heartbeatTimer)
          ws.current.send(heartbeat.msg);
        }, heartbeat.time);
      }

      ws.current.onmessage = (e) => {
        // console.log('WebSocket连接成功onmessage', e)
        if (e.data == "PONG") {
          console.info("心跳保持中ﮩ٨ـﮩﮩ٨ـ♡ﮩ٨ـﮩﮩ٨ﮩ෴ﮩ_____⁡");
          return;
        }
        const data = JSON.parse(e.data || "{}");

        if (bigDataType.includes(data.businessType)) {
          setWsBigData(data);
        } else if (atomDataType.includes(data.businessType)) {
          setAtom((draft) => {
            // draft 已拆箱 { val: T } 为 T
            draft.wsData = data;
          });
        } else {
          switch (data.businessType) {
            case separateDataType:
              setWsSeparateData(data);
              break;
            case separateData2Type:
              setWsSeparateData2(data);
              break;
            case separateData3Type:
              setWsSeparateData3(data);
              break;
            default:
              setMessage(data);
              break;
          }
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

  const webSocketInit = () => {
    if (!ws.current || ws.current.readyState === 3) {
      creatWebSocket();
    }
  };

  //  关闭 WebSocket
  const closeWebSocket = () => {
    // console.log('WebSocket连接close')
    ws.current?.close();
  };

  // 发送数据
  const sendMessage = (msg) => {
    // console.log("🚀 ~ sendMessage ~ msg:", ws.current, JSON.stringify(msg));
    if (ws.current?.readyState !== 3) {
      if (typeof msg == "object") {
        ws.current?.send(JSON.stringify(msg));
      }
    } else {
      setMessage({
        id: nanoid(),
        resContent: JSON.stringify({
          content: [{ data: "发送失败，请重试", return_type: "error" }],
        }),
        reqContent: JSON.stringify(msg.contentDo),
      });
      reconnect();
    }
  };

  //重连
  const reconnect = () => {
    try {
      closeWebSocket();
      ws.current = null;
      creatWebSocket();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    verify && webSocketInit();
    return () => {
      ws.current?.close();
    };
  }, [ws, verify]);

  return {
    wsData,
    wsBigData,
    wsAtomData,
    wsSeparateData,
    wsSeparateData2,
    wsSeparateData3,
    readyState,
    closeWebSocket,
    reconnect,
    sendMessage,
  };
};
export default useWebsocket;
