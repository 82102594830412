import { useState, useEffect, useRef, Fragment, useContext } from "react";
import { Button, Space } from "antd";
import "./index.scss";

// 初始化展示
import InitalViewA from "./initalView/index.jsx";
// 用户输入
import UserFieldB from "./userField";

// 通用类型
import CommonFieldG from "./commonField.jsx";

function ChatPanel({
  chatList,
  sendMessage,
  aiStatus,
  showHistory,
  basisData,
}) {
  const logContentRef = useRef(null);
  const [scrolling, setScrolling] = useState(false); // 0 未滚动 1 已滚动 2 正在滚动
  const [shouldScroll, setShouldScroll] = useState(false); // 是否需要滚动到底部
  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ scrolling:", scrolling);
    // console.log("🚀 ~ useEffect ~ shouldScroll:", shouldScroll);
    if (scrolling && shouldScroll) scrollToBottom();
    // settings
  }, [chatList, shouldScroll, scrolling]);
  useEffect(() => {
    if (!aiStatus.messageInfo.isSending) {
      setShouldScroll(false);
      setScrolling(false);
    } else {
      setShouldScroll(true);
    }
  }, [aiStatus]);
  // 缓慢滚动到底部
  const scrollToBottom = () => {
    const logContentElement = logContentRef.current;
    if (logContentElement) {
      const targetScrollTop =
        logContentElement.scrollHeight - logContentElement.clientHeight;
      const startScrollTop = logContentElement.scrollTop;
      const distance = targetScrollTop - startScrollTop;
      // console.log("🚀 ~ scrollToBottom ~ distance:", distance);

      const duration = 300; // 滚动持续时间（毫秒）
      let startTime = null;

      const animation = (currentTime) => {
        // console.log("🚀 ~ animation ~ currentTime:", currentTime);
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        // console.log("🚀 ~ animation ~ progress:", progress);
        const easeOutQuad = (t) => t * (2 - t);

        logContentElement.scrollTop =
          startScrollTop + distance * easeOutQuad(progress);

        if (progress < 1) {
          requestAnimationFrame(animation);
        } else {
          // 确保最终滚动到底部
          logContentElement.scrollTop = targetScrollTop;
        }
      };

      requestAnimationFrame(animation);
    }
  };
  return (
    <div className="chatPanel">
      <div
        className="chatTextFild"
        ref={logContentRef}
        onWheel={(e) => {
          // console.log("鼠标滚动了", e);
          const logContentElement = logContentRef.current;
          const targetScrollTop =
            logContentElement.scrollHeight - logContentElement.clientHeight;
          const startScrollTop = logContentElement.scrollTop;
          const distance = targetScrollTop - startScrollTop;
          // console.log("🚀 ~ scrollToBottom ~ onWheel:", distance);
          if (distance >= 20) {
            setScrolling(() => false);
            setShouldScroll(() => false);
          } else {
            setShouldScroll(() => true);
            setScrolling(() => true);
          }
        }}
        // onScroll={(e) => {
        //   // console.log("🚀 ~ ChatPanel ~ e:", e);
        //   const logContentElement = e.target;
        //   const targetScrollTop =
        //     logContentElement.scrollHeight - logContentElement.clientHeight;
        //   const startScrollTop = logContentElement.scrollTop;
        //   const distance = targetScrollTop - startScrollTop;
        //   console.log("🚀 ~ scrollToBottom ~ distance:", distance);
        //   if (distance >= 20) {
        //     setScrolling(false);
        //     setShouldScroll(false);
        //   } else {
        //     setShouldScroll(true);
        //     setScrolling(true);
        //   }
        // }}
      >
        {/* 初始化展示 */}
        {chatList.length === 0 && <InitalViewA basisData={basisData} />}
        {chatList.map((item, index) => {
          if (!item?.resContent) return null;

          let itemResParse = {};

          if (item?.resContent === "Internal Server Error") {
            itemResParse = {
              content: [{ data: "返回失败，请重试！", return_type: "error" }],
            };
          } else {
            itemResParse = JSON.parse(item?.resContent || "{}");
          }
          let itemReqParse = JSON.parse(item?.reqContent || "{}");
          // console.log("🚀 ~ {chatList.map ~ itemReqParse:", itemResParse);

          return (
            <Fragment key={item.id + itemReqParse.role}>
              {itemReqParse?.role === "user" && (
                <UserFieldB sId={item.id} msg={itemReqParse} />
              )}
              {itemResParse?.role !== "user" && (
                <CommonFieldG
                  aiStatus={aiStatus}
                  sId={item.id}
                  msg={itemResParse}
                  wholeItem={item}
                  sendMessage={sendMessage}
                  showHistory={showHistory}
                />

                // eslint-disable-next-line react/jsx-no-comment-textnodes
              )}
            </Fragment>
          );
        })}
        {shouldScroll && !scrolling && (
          <Button
            className="scrollBottom"
            onClick={() => {
              setShouldScroll(true);
              setScrolling(true);
            }}
          >
            滚到底部
          </Button>
        )}
      </div>
    </div>
  );
}

export default ChatPanel;
