import React, { Fragment, useEffect, useRef, useState } from "react";
import { BorderBox13 } from "@jiaminghi/data-view-react";
import { Space, Tag, Button, message } from "antd";
import { nanoid } from "nanoid";

import threeInit from "../process/commonModel/threeInit";
import styles from "./modelHFCR.scss";
import { loadGLTFMod } from "../process/commonModel/loadModel";
import { opacityMaterial } from "./modules/modelOpera";
import { css2DLabel } from "../continuousFlowModel/modules/modelParam";
import { getSearch } from "~/utils/jstools.js";

import { getFunctionList } from "~/api/common";
import "~/index.css";

let originMaterial,
  modules = {},
  moduleObjs,
  shake = {},
  shelf = {},
  staticModuleObjs,
  simuModuleObjs,
  simuShake = {},
  xAxis,
  yAxis,
  channelObjs;

export default function ModelHFCR(props) {
  const cfRef = useRef();
  //连续流threeInit对象
  const threeObj = useRef();
  const [moduleName, setModuleName] = useState(null);
  const [modelLoaded, setModelLoaded] = useState(null);
  const moduleFunMap = useRef({});
  const settingModel = useRef();

  const labelDiv = useRef();
  // const shelf = useRef()

  useEffect(() => {
    if (modelLoaded) {
      props.initModelLoaded();
    }
  }, [modelLoaded]);

  useEffect(() => {
    try {
      getFunctionList({
        formulaBasisId: getSearch(window.location.href)?.id,
      }).then((res) => {
        if (res.code == 0) {
          for (let key in res.data) {
            moduleFunMap.current[key] = { name: key, fun: [] };
            res.data[key].forEach((item) => {
              moduleFunMap.current[key]["fun"].push(item.functionName);
              moduleFunMap.current[key]["name"] = item.blockName;
            });
          }
        } else message.error(res.msg);
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      originMaterial = null;
      modules = null;
      staticModuleObjs = null;
      moduleObjs = null;
      simuModuleObjs = null;
      threeObj?.current?.scene?.traverse((obj) => {
        if (obj.type === "Mesh") {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      threeObj.current?.scene?.clear();
    };
  }, []);
  useEffect(() => {
    threeObj.current && threeObj.current.onWindowResize();
    // if (threeObj?.current?.scene?.add == undefined) return
    // threeObj.current?.scene?.add(settingModel?.current)
    // threeObj.current?.scene?.remove(simuModel?.current)
  }, [props.current]);

  useEffect(() => {
    return () => {
      threeObj?.current?.deleteEffect();
      threeObj?.current?.scene?.clear();
    };
  }, []);

  const resizeUpdate = () => {
    threeObj.current && threeObj.current.onWindowResize();
  };
  threeObj.current && window.addEventListener("resize", resizeUpdate);

  const modelInit = (equipmentType) => {
    try {
      loadGLTFMod(equipmentType).then((res) => {
        const photocatalysisObj = res.scene;
        let tempShelf = {};
        let tempModuleObjs = {
          A1: [],
          A2: [],
          A3: [],
          B1: [],
          B2: [],
          B3: [],
          D1: [],
          D2: [],
          D3: [],
          E: [],
        };
        let tempChannel = { A1: [], A2: [], A3: [], D2: [] };
        photocatalysisObj.castShadow = true;

        photocatalysisObj.traverse((obj) => {
          obj.castShadow = true;
          obj["originPosition"] = obj.position;
          let nameArr = obj.name.split("-");
          obj.name == "x" && (xAxis = obj);
          obj.name == "y" && (yAxis = obj);

          let moduleName = nameArr[0];
          if (nameArr[0] == "D") {
            moduleName = nameArr[0] + nameArr[1];
          }
          if (nameArr[2] === "point") {
            // console.log(nameArr[0], nameArr[1], nameArr[3]);
            let tempArr = nameArr[3].split("_");
            if (!tempArr[1]) {
              tempModuleObjs[nameArr[0]][nameArr[3] - 1] = obj;
              let labelObj = css2DLabel(labelDiv.current);
              // labelObj.position.y -= 200
              labelObj.element.innerText = `${nameArr[3]}号`;
              let tempObj = {
                number: nameArr[3],
                modType: "point",
                notClick: false,
                moduleName: moduleName,
              };
              Object.assign(obj, tempObj);
              labelObj.layers.mask = 0;
              obj.add(labelObj);
              obj.traverse((child) => Object.assign(child, tempObj));
              // obj.children[0].position.set(0, -2, 0)
              obj.children[1].position.set(0, 100, 0);

              if (nameArr[0] == "D2" || nameArr[0] == "D1") {
                for (let i = 0; i < 8; i++) {
                  for (let j = 0; j < 12; j++) {
                    if (i == 0 && j == 0) continue;
                    let hole = obj.clone();
                    hole.position.y += 9 * i;
                    hole.position.x -= 9 * j;
                    let number = i * 12 + j;
                    let tempObj = {
                      number: number + 1,
                      modType: "point",
                      notClick: false,
                      moduleName: moduleName,
                    };
                    hole.castShadow = true;
                    hole["originPosition"] = obj.position;
                    Object.assign(hole, tempObj);
                    hole.traverse((child) => Object.assign(child, tempObj));

                    tempModuleObjs[moduleName][number] = hole;
                    hole.children[1].element.innerText = `${number + 1}号`;
                    hole.children[1].position.y -= 5;
                    if (hole.children[0].isMesh) {
                      hole.children[0].material =
                        hole.children[0].material.clone();
                      hole.children[0].originMaterial =
                        hole.children[0].material.clone();
                    }
                    obj.parent.add(hole);
                  }
                }
              }
              // if (nameArr[0] == 'F') {
              //     obj.visible = false
              // }
            }
          } else if (nameArr[2] === "shelf") {
            obj.modType = "shelf";
            // tempShelf[`${nameArr[0]}-${nameArr[1]}`] = obj
            obj.moduleName = moduleName;

            tempShelf[moduleName] = obj;
            obj.traverse((obj) => {
              obj.modType = "shelf";
              obj.moduleName = moduleName;
            });
          } else if (nameArr[2] === "channel") {
            if (obj.material) {
              obj.material.transparent = true;
              obj.material.opacity = 0.2;
              obj.material.color = "rgb(125,125,125)";
            }
            let labelObj = css2DLabel(labelDiv.current);
            labelObj.element.innerText = `通道${nameArr[1]}`;
            let tempObj = {
              number: nameArr[1],
              modType: "channel",
              notClick: false,
              moduleName: moduleName,
            };
            Object.assign(obj, tempObj);
            labelObj.layers.mask = 0;
            obj.add(labelObj);
            tempChannel[moduleName][nameArr[1] - 1] = obj;
          }

          if (obj.isMesh) {
            obj.material = obj.material.clone();
            obj.originMaterial = obj.material.clone();
          }
        });

        shelf = tempShelf;
        moduleObjs = tempModuleObjs;
        staticModuleObjs = tempModuleObjs;
        channelObjs = tempChannel;
        photocatalysisObj.scale.set(2, 2, 2);
        threeObj.current.scene.add(photocatalysisObj);
        threeObj.current.addGround();
        // photocatalysisObj.rotation.z += Math.PI / 2
        // photocatalysisObj.position.y -= 100
        setModelLoaded(1);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modelLoaded) {
      props.initModelLoaded();
    }
  }, [modelLoaded]);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", resizeUpdate);
      originMaterial = null;
      modules = null;
      staticModuleObjs = null;
      moduleObjs = null;
      threeObj.current.deleteEffect();
      threeObj.current?.scene?.clear();
    };
  }, []);
  useEffect(() => {
    threeObj.current && threeObj.current.onWindowResize();
  }, [props.current]);

  useEffect(() => {
    threeObj.current = new threeInit({ ref: cfRef, labelDiv: labelDiv });
    const resizeObserver = new ResizeObserver((entries) => {
      threeObj?.current?.onWindowResize();
    });

    // if (dom_box.current) {
    resizeObserver.observe(cfRef.current);
    threeObj.current.initDeskMLCamera();
    // threeObj.current.initQDLight()
    threeObj.current.initDeskMLLight();

    threeObj.current.initControls();
    threeObj.current.initRenderer();
    // modelInit('hydrothermalNew')
    modelInit("planeHFM5");
    threeObj.current.animate();
  }, []);

  const handleOnClick = (e) => {
    if (props.current == 2) {
      return;
    }
    let attriObj = threeObj.current.onPointerClick(
      e,
      "ML",
      threeObj.current.canvas.current
    );
    // console.log("🚀 ~ file: index.jsx:193 ~ handleOnClick ~ e:", attriObj)
    let clickObj = { type: "", moduleName: "", number: "" };
    const typeMap = {
      point: "bot",
      shelf: "module",
      channel: "channel",
    };
    if (attriObj && !attriObj.notClick) {
      const { number, moduleName, modType } = attriObj;
      // modType && attriObj.traverse(child => {
      // child.geometry && child.material.color.set(0xff0000);
      clickObj.type = typeMap[modType];

      clickObj.number = number + "";
      clickObj.moduleName = moduleName;
    }

    props.handleModelClick(clickObj);
  };

  const handelonHover = (e) => {
    if (props.current == 2) {
      return;
    }
    let attriObj = threeObj.current.onPointerClick(
      e,
      "ML",
      threeObj.current.canvas.current
    );
    for (let key in shelf) {
      // eslint-disable-next-line no-loop-func
      shelf[key].traverse((child) => {
        if (child.geometry) {
          child.material = child.originMaterial?.clone();
          // shelf[key].lastColor && child.material.color.set(shelf[key]?.lastColor)
          if (shelf[key].lastColor) {
            child.material.color.set(shelf[key]?.lastColor);
            child.material.emissive.set(shelf[key]?.lastColor);
          }
        }
      });
    }
    for (let key in moduleObjs) {
      moduleObjs[key].forEach((item) => {
        const { lastColor } = item;
        // if (key == 'F') {
        //     item.visible = false
        // }
        item.traverse((child) => {
          if (child.geometry) {
            if (lastColor) {
              child.material.color.set(lastColor);
              if (child?.material?.emissive) {
                child.material.emissive.set(lastColor);
              }
            } else {
              child.material = child.originMaterial.clone();
            }
          }
        });
        item.children.at(-1).show || (item.children.at(-1).layers.mask = 0);
      });
    }
    // console.log(channelObjs);
    for (let key in channelObjs) {
      channelObjs[key].forEach((item) => {
        const { lastColor } = item;
        // if (key == 'B') {
        //     item.visible = false
        // }
        item.traverse((child) => {
          if (child.geometry) {
            if (lastColor) {
              child.material.color.set(lastColor);
              child.material.emissive.set(lastColor);
            } else {
              child.material = child.originMaterial.clone();
            }
          }
        });
        item.children.at(-1).show || (item.children.at(-1).layers.mask = 0);
      });
    }
    if (attriObj && !attriObj.notClick) {
      const { modType } = attriObj;
      if (modType === "shelf" || modType === "channel") {
        // if (modType === 'shelf') {
        attriObj.traverse((child) => {
          child.material = opacityMaterial.clone();
          const { moduleName, number } = child;
          number
            ? setModuleName(moduleName + number)
            : setModuleName(child.moduleName);
        });
      } else if (modType === "point") {
        // console.log(attriObj);
        let changeObj = attriObj.parent;
        changeObj.visible = true;
        changeObj.children.at(-1).layers.mask = 1;
        changeObj.traverse((child) => {
          if (child.geometry) {
            child.material.opacity = 1;
            child.material.color.set(0x00ff00);
            child.material?.emissive?.set(0x00ff00);
          }
          // child.parent.parent.children.at(-1).layers.mask = 1
        });
      } else {
        setModuleName(null);
      }
    } else {
      setModuleName(null);
    }
  };

  return (
    <div className={`${styles.cfMain} modelBg`}>
      <div
        ref={cfRef}
        className={styles.cfRef}
        onClick={handleOnClick}
        onMouseMove={handelonHover}
      ></div>
      <div className={styles.labelDiv} ref={labelDiv}></div>
      {moduleName && moduleFunMap?.current[moduleName] && (
        <div className={styles.displayPanel}>
          <div className={styles.panelContent}>
            <p className={styles.title}>模块名称：</p>
            <p className={styles.moduleName}>
              {moduleFunMap.current[moduleName]?.name}
            </p>
            <p className={styles.title}>可执行操作：</p>
            <Space size={[0, 8]} wrap className={styles.moduleFun}>
              {moduleName &&
                moduleFunMap.current[moduleName].fun?.map((item) => (
                  <Tag color="blue" key={nanoid()}>
                    {item}
                  </Tag>
                ))}
            </Space>
          </div>
        </div>
      )}
    </div>
  );
}

export {
  originMaterial,
  modules,
  moduleObjs,
  channelObjs,
  shake,
  simuShake,
  shelf,
  staticModuleObjs,
  simuModuleObjs,
  xAxis,
  yAxis,
};
