import { useState, useEffect, useRef } from "react";
import { Button, Input, Space } from "antd";
import "./index.scss";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import RemarkMath from "remark-math";
import RemarkBreaks from "remark-breaks";
import RehypeKatex from "rehype-katex"; // 数学公式
import RemarkGfm from "remark-gfm";
import RehypeHighlight from "rehype-highlight";
import { useDebouncedCallback, useThrottledCallback } from "use-debounce";
import mermaid from "mermaid";
import eventBus from "~/utils/hooks/eventBus";
import { symbolFlow } from "./text";

import { store } from "~/store";
export function Mermaid(props) {
  const ref = useRef(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (props.code && ref.current) {
      mermaid
        .run({
          nodes: [ref.current],
          suppressErrors: true,
        })
        .catch((e) => {
          setHasError(true);
          console.error("[Mermaid] ", e.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  function viewSvgInNewWindow() {
    const svg = ref.current?.querySelector("svg");
    if (!svg) return;
    const text = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([text], { type: "image/svg+xml" });
    // showImageModal(URL.createObjectURL(blob));
  }

  if (hasError) {
    return null;
  }

  return (
    <div
      className="no-dark mermaid"
      style={{
        cursor: "pointer",
        overflow: "auto",
      }}
      ref={ref}
      onClick={() => viewSvgInNewWindow()}
    >
      {props.code}
    </div>
  );
}
export function PreCode(props) {
  console.log("🚀 ~ PreCode ~ props:", props);
  const ref = useRef(null);
  const refText = ref.current?.innerText;
  const [mermaidCode, setMermaidCode] = useState("");

  const renderMermaid = useDebouncedCallback(() => {
    if (!ref.current) return;
    const mermaidDom = ref.current.querySelector("code.language-mermaid");
    if (mermaidDom) {
      setMermaidCode(mermaidDom.innerText);
    }
  }, 600);

  useEffect(() => {
    setTimeout(renderMermaid, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refText]);

  return (
    <>
      {mermaidCode.length > 0 && (
        <Mermaid code={mermaidCode} key={mermaidCode} />
      )}
      <pre ref={ref}>
        {/* <span
          className="copy-code-button"
          onClick={() => {
            if (ref.current) {
              const code = ref.current.innerText;
              copyToClipboard(code);
            }
          }}
        ></span> */}
        {props.children}
      </pre>
    </>
  );
}
export default function MarkdownContent({
  sId,
  sindex,
  sourceData,
  aiStatus,
  content,
  compareTexts,
}) {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");

  const [streamContent, setStreamContent] = useState("");
  const [symbolContent, setSymbolContent] = useState([]);
  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ sourceData:", sourceData);

    if (sourceData?.return_type === "method") {
      setSymbolContent(symbolFlow);
    }
    // 只有刚返回的消息的id = sId，则发送消息给worker，否则直接添加到streamContent中
    const worker = new Worker(new URL("./worker.js", import.meta.url));
    // console.log(
    //   "🚀 ~ useEffect ~ aiStatus:",
    //   aiStatus.messageInfo?.msgId,
    //   sId,
    //   userInfo?.id,
    //   returnType
    // );
    if (
      aiStatus.messageInfo?.msgId === sId &&
      sId !== userInfo?.id &&
      content
    ) {
      worker.onmessage = function (e) {
        // console.log("🚀 ~ useEffect ~ e:", e.data);
        if (e.data === "*end*") {
          eventBus.$emit("AIStatus", {
            messageInfo: {
              msgId: "",
              isSending: false,
            },
          });
        } else {
          setStreamContent((prevText) => {
            compareTexts(sindex, prevText + e.data);
            return prevText + e.data;
          });
        }
      };
      let longContent = content;
      if (longContent) {
        // console.log("🚀 ~ useEffect ~ longContent:", longContent);
        worker.postMessage(longContent);
      }
    } else {
      // console.log("🚀 ~ useEffect ~ content:", content);
      // if (!aiStatus.messageInfo?.isFinish)
      // 处理空文本时，直接结束发送状态
      if (!content) {
        eventBus.$emit("AIStatus", {
          messageInfo: {
            msgId: "",
            isSending: false,
          },
        });
      }

      let longContent = content;

      setStreamContent((prevText) => longContent);
    }

    return () => {
      worker.terminate();
    };
  }, [content, aiStatus]);
  return (
    <div className="markdownBox">
      <ReactMarkdown
        remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
        // rehypePlugins={[
        //   RehypeKatex,
        //   [
        //     RehypeHighlight,
        //     {
        //       detect: false,
        //       ignoreMissing: true,
        //     },
        //   ],
        // ]}
        //   children={markdown2}
        components={{
          // pre: PreCode,
          span: (props) => {
            if (props.className === "copy-code-button") {
              return null;
            } else return <span {...props} />;
          },
          code(props) {
            const { children, className, node, ...rest } = props;
            //   console.log("🚀 ~ code ~ props:", props);
            const match = /language-(\w+)/.exec(className || "");
            //   console.log("🚀 ~ code ~ match:", match);
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={dark}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {streamContent}
      </ReactMarkdown>

      {sourceData.return_type === "method" && symbolContent.length > 0 && (
        <div className="flowIcon">
          <h3>符号：</h3>
          <div className="symbolFlow">
            {symbolContent.map((item, index) => {
              // console.log("🚀 ~ {symbolContent.map ~ item:", item);
              if (item.functionCode === "startHeatB") {
                return <div className={`symbol jiare`}></div>;
              } else if (item.functionCode === "startAgitationB") {
                return <div className={`symbol zhenyao`}></div>;
              } else if (item.functionCode === "stopHeatB") {
                return <div className={`symbol jiangwen`}></div>;
              }
              return <div className={`symbol yiye`}></div>;
            })}
            {/* <div className="symbol yiye"></div>
            <div className="symbol jiare"></div>
            <div className="symbol zhenyao"></div>
            <div className="symbol jiangwen"></div> */}
          </div>
          {/* <img
            src="https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/AI-Lab/flow-icon.png"
            alt=""
          /> */}
        </div>
      )}
    </div>
  );
}
