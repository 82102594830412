import React, { Fragment, useEffect, useRef, useState } from "react";
import { BorderBox13 } from "@jiaminghi/data-view-react";
import { Space, Tag, Button, message } from "antd";
import { nanoid } from "nanoid";
// import threeInit from '../process/commonModel/threeInit'
import threejsInit from "~/components/threejsInit/threejsInit";
import ResourceTracker from "~/components/threejsInit/trackResource";

import styles from "./index.scss";
import { loadGLTFMod } from "../process/commonModel/loadModel";
import { opacityMaterial } from "./modules/modelOpera";
import { css2DLabel } from "../continuousFlowModel/modules/modelParam";
import { getSearch } from "~/utils/jstools.js";
import { getFunctionList } from "~/api/common";
import { SelectionBox } from "three/examples/jsm/interactive/SelectionBox.js";
import eventBus from "~/utils/hooks/eventBus";
import "~/index.css";
import { setColor } from "./modules/modelOpera";
import { Object3D } from "three";
import * as THREE from "three";

let originMaterial,
  modules = {},
  moduleObjs,
  heat,
  heatObjs,
  shake = {},
  shelf = {},
  staticModuleObjs,
  simuModuleObjs,
  simuShake = {},
  xAxis,
  yAxis,
  planeObj;

export default function Equipment02(props) {
  const cfRef = useRef();
  //连续流threeInit对象
  const threeObj = useRef();
  const [moduleName, setModuleName] = useState(null);
  const [modelLoaded, setModelLoaded] = useState(null);

  const [selectedBottles, setSelectedBottles] = useState(null);

  const moduleFunMap = useRef({});
  // const settingModel = useRef()

  const labelDiv = useRef();
  // const shelf = useRef()
  useEffect(() => {
    if (selectedBottles) {
      props.handleModelClick(selectedBottles);
    }
  }, [selectedBottles]);

  useEffect(() => {
    if (modelLoaded) {
      props.initModelLoaded();
    }
  }, [modelLoaded]);

  useEffect(() => {
    try {
      getFunctionList({
        formulaBasisId: getSearch(window.location.href)?.id,
      }).then((res) => {
        if (res.code == 0) {
          for (let key in res.data) {
            moduleFunMap.current[key] = { name: key, fun: [] };
            res.data[key].forEach((item) => {
              moduleFunMap.current[key]["fun"].push(item.functionName);
              moduleFunMap.current[key]["name"] = item.blockName;
            });
          }
        } else message.error(res.msg);
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      originMaterial = null;
      modules = null;
      staticModuleObjs = null;
      moduleObjs = null;
      simuModuleObjs = null;
      eventBus.$off("batchSelect");
      threeObj?.current?.scene?.traverse((obj) => {
        if (obj.type === "Mesh") {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      threeObj.current?.scene?.clear();
    };
  }, []);
  useEffect(() => {
    threeObj.current && threeObj.current.onWindowResize();
    // threeObj.current?.scene?.add(settingModel?.current)
    // threeObj.current?.scene?.remove(simuModel?.current)
  }, [props.current]);

  const resizeUpdate = () => {
    threeObj.current && threeObj.current.onWindowResize();
  };
  threeObj.current && window.addEventListener("resize", resizeUpdate);

  const modelInit = (equipmentType) => {
    try {
      loadGLTFMod(equipmentType).then((res) => {
        const photocatalysisObj = res.scene.children[0];
        // console.log("res---------------,", photocatalysisObj);
        let tempShelf = {
          A: [],
          B: [],
          C: [],
          D: [],
          E: [],
          F: [],
          G: [],
          H: [],
          I: [],
          J: [],
          K: [],
          L: [],
          M: [],
          O: [],
          P: [],
          Q: [],
        };
        let tempModuleObjs = {
          A: [],
          B: [],
          C: [],
          D: [],
          E: [],
          F: [],
          G: [],
          H: [],
          I: [],
          J: [],
          K: [],
          L: [],
          M: [],
          O: [],
          P: [],
          Q: [],
        };
        photocatalysisObj.castShadow = true;

        photocatalysisObj.traverse((obj) => {
          obj.castShadow = true;
          obj["originPosition"] = obj.position;
          let nameArr = obj.name.split("-");
          obj.name == "xAxis" && (xAxis = obj);
          obj.name == "yAxis" && (yAxis = obj);

          if (nameArr[2] === "point") {
            // console.log(nameArr[0], nameArr[1], nameArr[3]);
            let tempArr = nameArr[3].split("_");
            if (!tempArr[1]) {
              tempModuleObjs[nameArr[0]][nameArr[3] - 1] = obj;
              let labelObj = css2DLabel(labelDiv.current);
              equipmentType === "hydrothermal" && (labelObj.position.y -= 500);
              labelObj.element.innerText = `${nameArr[3]}号`;
              let tempObj = {
                number: nameArr[3],
                modType: "point",
                notClick: false,
                moduleName: nameArr[0],
              };

              if (nameArr[0] == "J" || nameArr[0] == "L") {
                tempObj.notClick = true;
              }
              Object.assign(obj, tempObj);
              labelObj.layers.mask = 0;
              obj.add(labelObj);
              obj.traverse((child) => Object.assign(child, tempObj));
            }
          } else if (nameArr[2] === "shelf") {
            obj.modType = "shelf";
            obj.moduleName = nameArr[0];
            // tempShelf[`${nameArr[0]}-${nameArr[1]}`] = obj
            tempShelf[nameArr[0]] = obj;
            obj.traverse((child) => {
              child.modType = "shelf";
              child.moduleName = nameArr[0];
            });
          } else if (nameArr[2] === "shake") {
            obj.modType = "shake";
            shake["C"] = obj;
            obj.traverse((obj) => (obj.modType = "shake"));
          }
          if (obj.isMesh) {
            obj.material = obj.material.clone();
            obj.originMaterial = obj.material.clone();
          }
          // obj.name === 'needle' && (obj.visible = false)
        });

        shelf = tempShelf;
        moduleObjs = tempModuleObjs;
        staticModuleObjs = tempModuleObjs;

        // console.log(shelf, tempModuleObjs);
        // settingModel.current = photocatalysisObj
        let newGroup = new Object3D();
        newGroup.add(photocatalysisObj);
        // newGroup.rotation.x = Math.PI / 3
        newGroup.position.y = -7;
        let pcObj = track(newGroup);
        planeObj = newGroup;
        threeObj.current?.scene?.add(pcObj);
        // threeObj.current.scene.add(photocatalysisObj)
        threeObj.current.addGround();
        // photocatalysisObj.rotation.z += Math.PI / 2
        // photocatalysisObj.position.y -= 5

        setModelLoaded(1);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modelLoaded) {
      props.initModelLoaded();
    }
  }, [modelLoaded]);

  useEffect(() => {
    threeObj.current && threeObj.current.onWindowResize();
  }, [props.current]);

  let resMgr = new ResourceTracker();
  const track = resMgr.track.bind(resMgr);

  const [selectionBox, setSelectionBox] = useState();

  useEffect(() => {
    try {
      threeObj.current = new threejsInit({ ref: cfRef, labelDiv: labelDiv });
      threeObj.current.init();
      const resizeObserver = new ResizeObserver((entries) => {
        threeObj?.current?.onWindowResize();
      });

      // if (dom_box.current) {
      resizeObserver.observe(cfRef.current);
      threeObj.current.controls.enableRotate = false;
      threeObj.current.camera.position.set(0, 25, 0);
      setSelectionBox(
        new SelectionBox(threeObj.current.camera, threeObj.current.scene)
      );
      // setHelper(new SelectionHelper(threeObj.current.renderer, 'selectBox'))
      modelInit("ZZTBPlane");
      // initDrawReact(cfRef.current)
      // modelInit('quantumDot')
      // threeObj.current.animate()
      return () => {
        window.removeEventListener("resize", resizeUpdate);
        resMgr && resMgr.dispose();
        threeObj?.current?.destrory();
        threeObj?.current?.scene?.traverse((obj) => {
          obj?.geometry?.dispose();
          obj?.material?.dispose();
        });
        threeObj?.current?.scene?.clear();
        threeObj.current.scene = null;
        threeObj.current = null;
        // document.removeEventListener('visibilitychange', handleVisibilityChange);
        originMaterial = null;
        modules = null;
        staticModuleObjs = null;
        moduleObjs = null;
      };
    } catch (error) {}
  }, []);

  const handleOnClick = (e) => {
    if (props.current == 2) {
      return;
    }
    let attriObj = threeObj.current.onPointerClick(
      e,
      "ML",
      threeObj.current.canvas.current
    );
    // console.log("🚀 ~ file: index.jsx:193 ~ handleOnClick ~ e:", attriObj)
    let clickObj = { type: "", moduleName: "", number: "" };
    const typeMap = {
      point: "bot",
      shelf: "module",
      heating: "channel",
    };
    if (attriObj && !attriObj.notClick) {
      // if(attriObj)
      const { number, moduleName, modType } = attriObj;
      // modType && attriObj.traverse(child => {
      // child.geometry && child.material.color.set(0xff0000);
      // console.log("🌲", attriObj);
      clickObj.type = typeMap[modType];

      clickObj.number = number + "";
      clickObj.moduleName = moduleName;
      if (clickObj.moduleName == "B") {
        clickObj.number = "1";
        clickObj.type = "point";
      }
      // })
    }
    // console.log(clickObj);
    setSelectedBottles(clickObj);
    // props.handleModelClick(clickObj)
  };

  const handelonHover = (e) => {
    if (props.current == 2) {
      return;
    }
    let attriObj = threeObj.current.onPointerClick(
      e,
      "ML",
      threeObj.current.canvas.current
    );
    for (let key in shelf) {
      // eslint-disable-next-line no-loop-func
      shelf[key].traverse((child) => {
        if (child.geometry) {
          child.material = child.originMaterial?.clone();
          // shelf[key].lastColor && child.material.color.set(shelf[key]?.lastColor)
          if (shelf[key].lastColor) {
            child.material.color.set(shelf[key]?.lastColor);
            child.material.emissive.set(shelf[key]?.lastColor);
          }
        }
      });
    }
    for (let key in heatObjs) {
      heatObjs[key].forEach((item) => (item.visible = false));
    }
    for (let key in moduleObjs) {
      moduleObjs[key].forEach((item) => {
        const { lastColor } = item;
        item.traverse((child) => {
          if (child.geometry) {
            if (lastColor) {
              child.material.color.set(lastColor);
              child.material.emissive.set(lastColor);
            } else {
              child.material = child.originMaterial.clone();
            }
          }
        });
        item.children.at(-1).show || (item.children.at(-1).layers.mask = 0);
      });
    }
    if (attriObj && !attriObj.notClick) {
      const { modType } = attriObj;
      if (modType == "heating") {
        attriObj.parent.visible = true;
      } else if (modType === "shelf") {
        // if (modType === 'shelf') {
        attriObj.traverse((child) => {
          child.material = opacityMaterial.clone();
          const { moduleName, number } = child;
          number
            ? setModuleName(moduleName + number)
            : setModuleName(child.moduleName);
        });
      } else if (modType === "point") {
        let moduleName = attriObj?.moduleName;
        let changeObj;

        if (moduleName == "C" || moduleName == "D1" || moduleName == "D2") {
          changeObj = attriObj?.parent?.parent;
        } else {
          changeObj = attriObj?.parent;
        }
        changeObj.children.at(-1).layers.mask = 1;
        changeObj.traverse((child) => {
          if (child.geometry) {
            child.material.color.set(0x00ff00);
            child.material?.emissive?.set(0x00ff00);
          }
          // child.parent.parent.children.at(-1).layers.mask = 1
        });
      } else {
        setModuleName(null);
      }
    } else {
      setModuleName(null);
    }
  };

  const handlePointerDown = (event) => {
    if (event.button == 2) return;
    setSelectedBottles(null);
    initDrawReact(event);
    for (const item of selectionBox.collection) {
      // console.log(item);
    }

    selectionBox.startPoint.set(
      ((event.clientX - cfRef.current.getBoundingClientRect().left) /
        cfRef.current.clientWidth) *
        2 -
        1,
      -(
        (event.clientY - cfRef.current.getBoundingClientRect().top) /
        cfRef.current.clientHeight
      ) *
        2 +
        1,
      0.5
    );
  };

  const handelPointerUp = (event) => {
    if (event.button == 2) return;
    // console.log(event);
    selectionBox.endPoint.set(
      ((event.clientX - cfRef.current.getBoundingClientRect().left) /
        cfRef.current.clientWidth) *
        2 -
        1,
      -(
        (event.clientY - cfRef.current.getBoundingClientRect().top) /
        cfRef.current.clientHeight
      ) *
        2 +
        1,
      0.5
    );

    const allSelected = selectionBox.select();

    let selectArr = [];
    for (let i = 0; i < allSelected.length; i++) {
      let nameArr = allSelected[i].parent.name?.split("-");

      if (
        nameArr &&
        nameArr[2] == "point" &&
        nameArr[0] != "J" &&
        nameArr[0] != "L" &&
        nameArr[0] != "O"
      ) {
        selectArr.push({
          type: "bot",
          moduleName: nameArr[0],
          number: nameArr[3],
        });
        // setColor({ equipmentType: 7, module: nameArr[0], number: nameArr[3], color: 'rgb(0,255,0)' })
      }
    }
    let selectedObjs = {
      type: undefined,
      moduleName: undefined,
      number: undefined,
      bottleArr: selectArr,
    };
    // props.getSelectedObjs(selectedObjs)

    // console.log("📕-----------", );
    setSelectedBottles(selectedObjs);
    if (!selectArr.length) {
      handleOnClick(event);
    }
    drawedReact?.current && cfRef.current.removeChild(drawedReact?.current);
    drawedReact.current = null;
  };

  const drawedReact = useRef();
  function initDrawReact(event) {
    // 创建矩形框
    // drawedReact?.current && cfRef.current.removeChild(drawedReact?.current)
    let drawReact = document.createElement("div");
    drawReact.id = "drawReact";
    // console.log(drawReact)
    drawReact.style.boxSizing = "border-box";
    drawReact.style.border = "1px dashed black";
    drawReact.style.position = "absolute";
    drawReact.style.display = "none";
    drawedReact.current = drawReact;
    cfRef.current.appendChild(drawReact);
    // 绑定鼠标事件--onmousedown
    // cfRef.current.addEventListener('mousedown', (event) => {
    //     console.log(isBatchSelect);
    //     if (!isBatchSelect) return
    // 初始化
    // let drawReact = document.getElementById('drawReact'); // 获取矩形框元素
    let areaInfo = cfRef.current.getBoundingClientRect(); // 返回元素的大小及其相对于视口的位置
    let reactWidth, reactHeight, reactTop, reactLeft; // 定义矩形框的宽、高、top、left
    // xy坐标是以画布的左上角为原点，方向矢量以向下和向右为正方向。
    let beginPoint = {}; // 标记起点
    let endPoint = {}; // 标记终点

    drawReact.style.display = "block"; // 进入画布按下鼠标显示默认矩形框
    // 鼠标按下的位置作为矩形框的起点，横纵坐标记为 x0, y0
    beginPoint = {
      x: event.clientX - areaInfo.x,
      y: event.clientY - areaInfo.y,
    };
    // 起点的横坐标
    let x0 = event.clientX - areaInfo.x;
    // 起点的纵坐标
    let y0 = event.clientY - areaInfo.y;
    // 绑定鼠标事件--onmousemove
    document.onmousemove = function (event) {
      // 终点的横坐标
      let x1 = event.clientX - areaInfo.x;
      // 终点的纵坐标
      let y1 = event.clientY - areaInfo.y;
      // 对终点相对于起点的位置进行分类讨论
      if (x1 >= x0 && y1 < y0) {
        // x 越界处理
        reactWidth =
          event.clientX >= areaInfo.right ? areaInfo.width - x0 : x1 - x0;
        reactLeft = x0;
        // y 越界处理
        reactHeight = event.clientY <= areaInfo.top ? y0 : y0 - y1;
        reactTop = event.clientY <= areaInfo.top ? 0 : y1;
        // 终点
        endPoint = { x: x0 + reactWidth, y: y0 - reactHeight };
      } else if (x1 < x0 && y1 < y0) {
        // x 越界处理
        reactWidth = event.clientX <= areaInfo.left ? x0 : x0 - x1;
        reactLeft = event.clientX <= areaInfo.left ? 0 : x1;
        // y 越界处理
        reactHeight = event.clientY <= areaInfo.top ? y0 : y0 - y1;
        reactTop = event.clientY <= areaInfo.top ? 0 : y1;
        // 终点
        endPoint = { x: x0 - reactWidth, y: y0 - reactHeight };
      } else if (x1 < x0 && y1 >= y0) {
        // x 越界处理
        reactWidth = event.clientX <= areaInfo.left ? x0 : x0 - x1;
        reactLeft = event.clientX <= areaInfo.left ? 0 : x1;
        // y 越界处理
        reactHeight =
          event.clientY >= areaInfo.bottom ? areaInfo.height - y0 : y1 - y0;
        reactTop = y0;
        // 终点
        endPoint = { x: x0 - reactWidth, y: y0 + reactHeight };
      } else if (x1 >= x0 && y1 >= y0) {
        // x 越界处理
        reactWidth =
          event.clientX >= areaInfo.right ? areaInfo.width - x0 : x1 - x0;
        reactLeft = x0;
        // y 越界处理
        reactHeight =
          event.clientY >= areaInfo.bottom ? areaInfo.height - y0 : y1 - y0;
        reactTop = y0;
        // 终点
        endPoint = { x: x0 + reactWidth, y: y0 + reactHeight };
      }

      drawReact.style.width = reactWidth + "px"; // 宽
      drawReact.style.height = reactHeight + "px"; // 高
      drawReact.style.top = reactTop + "px";
      drawReact.style.left = reactLeft + "px";
    };

    // 绑定鼠标事件--onmousedown
    document.onmouseup = function (event) {
      document.onmousemove = null;
      document.onmouseup = null;
      // 回调
      let options = {
        reactWidth,
        reactHeight,
        reactTop,
        reactLeft,
        beginPoint,
        endPoint,
      };
      // fn(options)
    };
    // })
    // onmousedown = function (event) {

    // }
  }

  return (
    <div className={`${styles.cfMain} modelBg`}>
      <div
        ref={cfRef}
        className={styles.cfRef}
        onMouseDown={handlePointerDown}
        onMouseUp={handelPointerUp}
        onMouseMove={handelonHover}
      ></div>
      <div className={styles.labelDiv} ref={labelDiv}></div>
      {moduleName && moduleFunMap?.current[moduleName] && (
        <div className={styles.displayPanel}>
          <div className={styles.panelContent}>
            <p className={styles.title}>模块名称：</p>
            <p className={styles.moduleName}>
              {moduleFunMap.current[moduleName]?.name}
            </p>
            <p className={styles.title}>可执行操作：</p>
            <Space size={[0, 8]} wrap className={styles.moduleFun}>
              {moduleName &&
                moduleFunMap.current[moduleName].fun?.map((item) => (
                  <Tag color="blue" key={nanoid()}>
                    {item}
                  </Tag>
                ))}
            </Space>
          </div>
        </div>
      )}
    </div>
  );
}

export {
  originMaterial,
  modules,
  moduleObjs,
  heatObjs,
  shake,
  simuShake,
  shelf,
  staticModuleObjs,
  simuModuleObjs,
  xAxis,
  yAxis,
  planeObj,
};
