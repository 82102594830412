import { useState, useContext, useEffect } from "react";
import { message } from "antd";

import {
  PluginContext,
  CommandContext,
  GlobalDataContext,
} from "./utils/reactContext.js";

import useCommand from "./utils/hooks/useCommand.js";

import ChatAI from "../chatAI";

// 水热B组件
import moveBottleB from "./plugins/ZZTB/moveBottleB/index.jsx";
import moveLiquidB from "./plugins/ZZTB/moveLiquidB/index.jsx";
import moveMultiBottle from "./plugins/ZZTB/moveMultiBottleB/index.jsx";
import startNitrogenBlowB from "./plugins/ZZTB/startNitrogenBlowB/index.jsx";
import startAgitationB from "./plugins/ZZTB/startAgitationB/index.jsx";
import { getSearch } from "~/utils/jstools";
import eventBus from "~/utils/hooks/eventBus";

import { getZZTBData } from "./utils/getZZT2DeviceInfo.js";

export default function AIBox({ setShowAI }) {
  let deviceInfo = JSON.parse(
    window.sessionStorage.getItem("deviceInfo") || "{}"
  );

  // 全局命令注入
  const conmands = useCommand();
  // 全局插件注入
  const plugin = {
    moveBottleB,
    moveLiquidB,
    moveMultiBottle,
    startNitrogenBlowB,
    startAgitationB,
  };
  // 全局数据注入
  const [globalData, setGlobalData] = useState({});
  const addGlobalData = (data) => {
    // console.log(
    //   "🚀 ~ eventBus.$on ~ data:1111111111111111111111",
    //   data,
    //   globalData
    // );
    setGlobalData({ ...globalData, ...data });
  };
  useEffect(() => {
    // console.log("🚀 ~ AIBox ~ globalData:", globalData);
    eventBus.$on("AIGlobalData", (data) => {
      addGlobalData(data);
    });

    return () => {
      eventBus.$off("AIGlobalData");
    };
  }, [globalData]);

  // 监听客户端的警告信息
  useEffect(() => {
    eventBus.$on("clientWarning", (obj) => {
      obj.fn(obj.text);
    });
    return () => {
      eventBus.$off("clientWarning");
    };
  }, []);
  const buildAIInfo = () => {
    // console.log("🚀 ~ buildAIInfo ~ deviceInfo:", deviceInfo);
    switch (deviceInfo?.equipmentType) {
      case 7:
        getZZTBData(333);
        break;
      default:
        break;
    }
  };

  // 进入流程配置页面后，开始初始化，设备的信息会更新全局数据
  useEffect(() => {
    let formulaId = getSearch(window.location.href)?.id;
    if (deviceInfo?.equipmentType !== globalData?.equipmentType && formulaId) {
      // console.log("🚀 ~ 初始化模型", deviceInfo);
      // console.log("🚀 ~ AIBox ~ device开:");
      // console.log("🚀 ~ useEffect ~ deviceData:", deviceData);
      setGlobalData({ ...globalData, ...deviceInfo });
      buildAIInfo();
    }
    return () => {
      // console.log("🚀 ~ useEffect ~ device关闭:");
    };
  }, [deviceInfo, globalData, getSearch(window.location.href)?.id]);

  return (
    <GlobalDataContext.Provider value={globalData}>
      <PluginContext.Provider value={plugin}>
        <CommandContext.Provider value={conmands}>
          <ChatAI setShowAI={setShowAI} />
        </CommandContext.Provider>
      </PluginContext.Provider>
    </GlobalDataContext.Provider>
  );
}
