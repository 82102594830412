import { useState, useEffect } from "react";
import { Button, Space, Input } from "antd";
import "./index.scss";

const { TextArea } = Input;
function HeaderField({
  basisData,
  setSendMsg,
  setShowAI,
  openNewTopic,
  showHistory,
}) {
  const [mode, setMode] = useState("normal");
  const [inputValue, setInputValue] = useState("");
  // 切换输入模式
  const changeMode = (mode) => {
    setMode(mode);
    setInputValue();
  };
  // 获取输入框的值
  const getValue = (e) => {
    setInputValue(e.target.value);
  };
  // 发送文本
  const sendText = () => {
    console.log(inputValue);
    setSendMsg({
      topicType: mode,
      content: inputValue,
    });
    setInputValue("");
  };
  return (
    <div className="aiHeaderField">
      <h3 className="title">
        {
          JSON.parse(basisData?.dataValue || '{"aiSystemName": "AI-Lab"}')
            ?.aiSystemName
        }
      </h3>
      <Space style={{ float: "right" }}>
        <div
          type="primary"
          className="actionItem"
          // size="small"
          onClick={() => showHistory()}
        >
          <i className="iconfont icon-shijian1"></i>
        </div>
        {/* 刷新 */}
        <div
          type="primary"
          className="actionItem"
          // size="small"
          onClick={() => openNewTopic()}
        >
          <i className="iconfont icon-shuaxin1"></i>
        </div>
        <div
          type="primary"
          // size="small"
          className="actionItem"
          onClick={() => setShowAI(false)}
        >
          <i className="iconfont icon-guanbi"></i>
        </div>
      </Space>
    </div>
  );
}

export default HeaderField;
