import { useState, useEffect, useContext } from "react";
import { message, Space, Input, Upload, Select, Spin } from "antd";
import "./index.scss";
import { LoadingOutlined } from "@ant-design/icons";
import eventBus from "~/utils/hooks/eventBus";

const { TextArea } = Input;
const userInfo = window.localStorage.getItem("userInfo") || "{}";
let token = JSON.parse(userInfo).token;
function InputField({
  scrollToAnchor,
  sendMessage,
  aiStatus,
  changeTopic,
  openNewTopic,
}) {
  const [mode, setMode] = useState("normal");
  const [inputValue, setInputValue] = useState("");
  const [isUploading, setUploading] = useState(false);
  // 上传文件的配置
  const [uploadConfig, setUploadConfig] = useState({
    name: "multipartFile",
    // action: `${process.env.REACT_APP_BASE_IP + process.env.REACT_APP_BASE_API}${
    //   process.env.NODE_ENV == "development" ? "" : "/"
    // }formula/basis-info/importBasisResultData?equipmentType=${
    //   deviceInfo?.equipmentType
    // }&encrypt=1&deviceNumber=${deviceInfo?.deviceNumber}`,
    showUploadList: false,

    beforeUpload: (file) => {
      // console.log("🚀 ~ file: form.jsx:31 ~ beforeUpload ~ file:", file)
      if (file.size > 100 * 1024 * 1024) {
        message.error("文件大小不能超过100M");
        return false;
      }
    },

    // accept: ".json,.zip,.ff,.ff2, .pdf , .doc, .docx",
    accept: ".json,.zip,.ff,.ff2, .pdf , .doc, .docx",
    action: "/common/aiDialogue/upload",
    headers: {
      Token: token,
    },
    onChange(info) {
      console.log("🚀 ~ onChange ~ info:", info);

      setUploading(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setUploading(false);
        sendFile(info.file.response.data);

        message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === "error") {
        setUploading(false);
        message.error(`${info.file.name} 文件上传失败`);
      }
    },
  }); // 切换输入模式
  const changeMode = (mode) => {
    setMode(mode);
    setInputValue();
  };
  // 获取输入框的值
  const getValue = (e) => {
    setInputValue(e.target.value);
  };
  const sendFile = (data) => {
    sendMessage({
      fileUrl: data?.fileUrl,
      role: "user",
      content: "",
      fileName: data.fileName,
      contentType: 1,
      actionType: "chat", // chat 聊天内容 info 论文信息 idea 创新idea method 方法信息 ff 配方文件 old-article 文章信息 summary 方向总结 new-article 润色后文章信息
    });
  };
  // 发送文本
  const sendText = () => {
    // console.log(inputValue);
    if (aiStatus.messageInfo.sending) return;
    if (inputValue === "") return;
    sendMessage({
      content: inputValue,
      role: "user",
      contentType: 0, // 输入类型， 0：文本 1：文件 2：图片 3：语音 4：视频
      actionType: "chat", // chat 聊天内容 info 论文信息 idea 创新idea method 方法信息 ff 配方文件 old-article 文章信息 summary 方向总结 new-article 润色后文章信息
    });
    setInputValue("");
  };

  // 语音输入
  const startSTT = () => {
    eventBus.$emit("STT_MODE", "voice");
  };
  useEffect(() => {
    eventBus.$on("RecordText", (text) => {
      setInputValue((prev) => prev + text);
    });
    return () => {
      eventBus.$off("RecordText");
    };
  }, []);
  return (
    <div className="inputField">
      <div className="btnNew" onClick={openNewTopic}>
        <i className="iconfont icon-tianjialiaotian"></i>
      </div>
      <div className="inputInner">
        <div className="inputBox">
          <TextArea
            placeholder="请输入"
            value={inputValue}
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={getValue}
            onPressEnter={sendText}
          ></TextArea>
        </div>
        <div className="actionFiled">
          <div className="rightField">
            <Space>
              <div className="topicFactory ">
                <Select
                  defaultValue="chat"
                  style={{ width: 120 }}
                  onChange={changeTopic}
                  options={[
                    { value: "chat", label: "普通对话" },
                    { value: "flowConfig", label: "流程配置" },
                    { value: "doi", label: "DOI" },
                    { value: "gtk", label: "钙钛矿", disabled: true },
                  ]}
                />
              </div>

              <Upload {...uploadConfig} disabled={isUploading}>
                <div className="actionBtn ">
                  {isUploading && (
                    <Spin
                      style={{ width: "100%", height: "100%" }}
                      className="loadingAvatar"
                      indicator={
                        <LoadingOutlined
                          style={{ width: "100%", height: "100%" }}
                          spin
                        />
                      }
                    />
                  )}
                  {!isUploading && (
                    <i
                      className="iconfont icon-i-wenjianshangchuan"
                      style={{ fontSize: 20 }}
                    ></i>
                  )}
                </div>
              </Upload>

              <div
                className={`actionBtn ${
                  aiStatus.isRecording ? "recording" : ""
                }`}
                onClick={startSTT}
              >
                <i
                  className="iconfont icon-mic-on"
                  style={{ fontSize: 20 }}
                ></i>
              </div>
              <div className="d-line"></div>
              <div className="actionBtn" onClick={sendText}>
                <i
                  className="iconfont icon-fasong1"
                  style={{ fontSize: 18 }}
                ></i>
              </div>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputField;
