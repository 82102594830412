import { useState, useEffect } from "react";
import { Button, List } from "antd";
import "./index.scss";

import eventBus from "~/utils/hooks/eventBus";
import { sendAI, getHistoryData, webflux } from "../../utils/ai-pl-api.js";
const userInfo = window.localStorage.getItem("userInfo") || "{}";
let token = JSON.parse(userInfo).token;
function HistoryView({ scrollToAnchor, sendMessage, showHistory }) {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    // webflux({
    //   contentDo: {
    //     content: "10.1016/j.colsurfa.2023.132679",
    //     contentType: 0,
    //     fileName: "string",
    //     fileUrl: "string",
    //     role: "25",
    //   },
    //   ext: {},
    //   id: "string",
    //   isAI: true,
    // }).then((res) => {
    //   // console.log("🚀 ~ res:", res);
    // });
    // console.log("🚀 ~ HistoryView ~ userInfo:", userInfo);
    getHistoryData({
      orgNumber: userInfo?.orgNumber,
      pageNum: 1,
      pageSize: 1000,
      userId: userInfo?.id,
    }).then((res) => {
      // console.log("🚀 ~ aiDialogue ~ res:", res);
      if (res.code === "0") {
        // res.data?.records 倒序排列
        setHistoryData(res.data?.records.reverse());
      }
    });
  }, []);
  const data = [
    {
      title: "10.1021/acs.macromol.3c02018",
    },
    {
      title: "10.1016/j.colsurfa.2023.132679",
    },
    {
      title: "帮我把实验报告转化为word",
    },
    {
      title: "帮我把专利转化为word",
    },
  ];

  return (
    <List
      itemLayout="horizontal"
      dataSource={historyData}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            onClick={() => showHistory(item)}
            title={
              <p>
                {item.name}
                <i
                  className="iconfont icon-arrow-right-line"
                  style={{ float: "right" }}
                ></i>
              </p>
            }
            style={{ cursor: "pointer" }}
          />
        </List.Item>
      )}
    />
  );
}

export default HistoryView;
