// 初始化状态
export const settings = {
  loading: false,
  theme: "#ffffff",
  language: localStorage.getItem("language") || "zh-CN",
  aiInfo: {
    AIChat: "0",
    AIPerfumery: "0",
    aiSystemName: "Research Copilot",
    airchem: "0",
    smartLab: "0",
    workstation: "0",
  },
};
