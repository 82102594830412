import { useState, useEffect } from "react";
import { store } from "~/store";
import routers from "./router/index";
import { Spin, FloatButton, message } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";

import i18n from "i18next";
import {
  NavLink,
  useRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Author from "./router/author";
import TourGuide from "~/components/guide";
import { isMobile } from "./utils/jstools";
import "./App.scss";
import AliAvatar from "~/components/aliAvatar";
import robot from "~/components/chatAI/images/ai-logo.png";
import eventBus from "~/utils/hooks/eventBus";

import { Resize, ResizeVertical, ResizeHorizon } from "react-resize-layout";
// import ChatAI from "~/components/chatAI";
import AIPL from "~/components/AIPL";
import Vconsole from "vconsole"; // 引入vconsole插件

export default function App() {
  const GetRoutes = () => useRoutes(routers); //一定要是函数内,路由表这根步注册一次就行 子路由无需注册
  const location = useLocation();
  // console.log("🚀 ~ App ~ location:", location?.pathname);
  const navigate = useNavigate();

  // 全局loading
  const [isLoading, setIsLoading] = useState(false);
  const [AIInfo, setAIInfo] = useState(false);
  const [guideInfoObj, setGuideInfoObj] = useState(false);
  const [aiStatus, setAiStatus] = useState({});
  // 监控dispatch提交的action改变
  store.subscribe(() => {
    const { settings, guideInfo, modelLoad, sysStatusInfo } = store.getState();
    // console.log("🚀 ~ store.subscribe ~ sysStatusInfo:", sysStatusInfo);
    setAiStatus(sysStatusInfo);
    setAIInfo(settings.aiInfo);
    // console.log("🚀 ~ file: App.jsx:19 ~ store.subscribe ~ settings:", guideInfo)
    if (modelLoad.loading || settings.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    // 移动端不展示引导
    if (!isMobile()) {
      setGuideInfoObj(guideInfo);
    }
  });

  useEffect(() => {
    // 调用函数
    detectLandscapeMode();

    // 初始化vconsole
    // const vConsole = new Vconsole();
  }, []);

  // 关闭自动执行
  const closeAutoComplete = () => {
    eventBus.$emit("AIStatus", { autoComplete: false });
  };
  const [showAI, setShowAI] = useState(false);
  const openAI = () => {
    setShowAI(!showAI);
  };
  const noShowAIArr = [
    "/login",
    "/404",
    "/cubeRoot",
    "/ringRoot",
    "/tree-root-ZZTA",
    "/digital-twin-ZZTB",
    "/digital-twin-ZZTA",
    "/digital-twin-PC",
    "/digital-twin-HFCR",
    "/smartLabTh",
  ];
  return (
    <>
      <Resize handleWidth="8px" handleColor="rgb(175 175 175)">
        <ResizeHorizon
          minWidth="750px"
          width={"100%"}
          className={`${showAI ? "" : "rootPanel"}`}
        >
          <Author>{GetRoutes()}</Author>
          {/* <FloatButton
            shape="circle"
            type="primary"
            style={{ top: 100, right: 20 }}
            icon={<img src={robot} alt="" style={{ width: 40, height: 40 }} />}
          /> */}
          {aiStatus?.autoComplete && (
            <div className="autoAICover" onClick={closeAutoComplete}>
              <h3>系统自动运行中</h3>
            </div>
          )}

          {/* 加载效果 */}
          <Spin
            spinning={isLoading}
            delay={0}
            size="large"
            wrapperClassName="rootSpin"
            className="rootSpin"
            style={{ zIndex: isLoading ? "10000" : "-1" }}
          ></Spin>

          {AIInfo.AIChat == 1 &&
            !showAI &&
            noShowAIArr.indexOf(location?.pathname) == -1 && (
              <div
                className="copilotPanel"
                style={{
                  position: "fixed",
                  bottom: 200,
                  right: 20,
                  zIndex: 100,
                  background: "#ccc",
                  width: 60,
                  height: 60,
                  overflow: "hidden",
                  borderRadius: 60,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 6px 0px #7031a0",
                }}
                onClick={openAI}
              >
                <img src={robot} alt="" style={{ width: 60, height: 60 }} />
              </div>
            )}
        </ResizeHorizon>
        {showAI && noShowAIArr.indexOf(location?.pathname) == -1 && (
          <ResizeHorizon minWidth="460px" width="650px">
            <AIPL setShowAI={setShowAI}></AIPL>
          </ResizeHorizon>
        )}
      </Resize>

      {/* 全局引导组件 */}
      <TourGuide options={guideInfoObj} />
      <AliAvatar />
    </>
  );

  function detectLandscapeMode() {
    let isLandscape = false;

    // 初始检测是否为横屏
    if (window.orientation === 90 || window.orientation === -90) {
      isLandscape = true;
    }
    let cacheWidth = 0;
    // 监听 orientationchange 事件
    window.addEventListener("orientationchange", function (e) {
      const screenWidth = e.target.innerWidth;

      const maxMobileWidth = 768; // 根据需求调整这个阈值
      let isMobile = cacheWidth <= maxMobileWidth;
      // console.log("🚀 ~ isMobile:", e, isMobile, screenWidth)

      // 根据 window.orientation 判断是否为横屏
      if (
        isMobile &&
        (window.orientation === 90 || window.orientation === -90)
      ) {
        isLandscape = true;
        message.warning("横屏后，手机端部分功能体验受影响！");
      } else {
        isLandscape = false;
      }
      cacheWidth = screenWidth;
    });
  }
}
