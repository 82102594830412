export let symbolFlow = [
  {
    functionCode: "commonInjectB", // 注液
    injectionVolume: "2", // 注液量
    startHeatB: {
      // 并行加热
      temperature: "2", // 温度
      executeTime: "22", // 时间
    },
    startAgitationB: {
      // 并行振荡
      speed: 30, // 速度
      executeTime: "120", // 时间
    },
  },
  {
    functionCode: "startHeatB", // 开始加热
    temperature: "2", // 温度
  },
  {
    functionCode: "startAgitationB", // 开始振荡
    speed: "2", // 速度
    executeTime: "2",
  },
  {
    functionCode: "stopHeatB", // 开始振荡
  },
];
export const markdownText = `### 总结

#### 研究背景与目的：

- 探讨元宇宙技术在纳米材料合成中的应用，特别是通过结合自动化合成技术和人类指导的人工智能方法来提高实验效率。
- 介绍一种基于虚拟实验室系统和人工智能增强的方法，以优化量子点的合成过程。

#### 方法论：

1. **构建虚拟实验室系统**：利用 AI、机器人技术和 VR/AR 技术创建一个先进的科研环境。该系统包括高通量并行反应器和连续流反应器等设备的数字孪生。
2. **训练与优化流程**：通过重复文献中的量子点合成实验来收集数据，用这些数据训练模型，以预测化学反应的过程。
3. **虚拟-物理结合优化**：
       - 详细模拟 NaYF4:Yb/Tm 纳米晶体的光激发色谱变化，并映射到真实世界中进行验证。
       - 利用扩散 AI 模型来实现精确的时间粒度和实验参数调整。

#### 主要发现与结论：

1. **效率提升**：通过虚拟环境中的快速优化，极大地减少了传统实验室试错法所需的时间。
2. **质量控制**：利用人类专家的知识指导 AI 进行实时调整，确保合成结果准确且可再现。
3. **未来展望**：该方法为未来的材料科学计算平台提供了可能，并展示了其在纳米材料合成和应用中的潜力。

#### 实验步骤：

1. 准备溶液：将 LnCl3（Y, Yb, Tm/Er）溶解于甲醇中，加入 OA 和 ODE。
2. 加热反应：加热至 150°C 并维持 30 分钟以形成澄清的黄色溶液。
3. 引入碱性物质：冷却后加入 NH4F 和 NaOH 的甲醇溶液，并搅拌 30 分钟。
4. 慢慢升温：将混合物逐渐加热到 110°C，保持 30 分钟蒸发溶剂。
5. 高温反应：在氩气保护下迅速加热至 305°C 并维持 1.5 小时。
6. 分离产物：通过乙醇洗涤和离心分离产物，并用环己烷重新分散。

#### 相关内容：

- 详细介绍了虚拟实验室中 NaYF4:Yb/Tm 纳米晶体合成的硬件和软件工程工作。
- 提供了样品表征光谱和 TEM 图像、力场系数等补充信息。


`;
