import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { Button, Input, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import eventBus from "~/utils/hooks/eventBus";
import { nanoid } from "nanoid";
import Markdown from "./markdown";
import OperaButtons from "./operaButtons";
import "./index.scss";
import MarkdownEditor from "./markdownEditor";

import { PluginContext } from "~/components/AIPL/utils/reactContext.js";

import { saveDetail } from "../../utils/ai-pl-api";
import GeneFormulaFile from "./geneFormulaFile";

let cacheMsg = [];
export default function CommonView({
  msg,
  sId,
  wholeItem,
  sendMessage,
  aiStatus,
  showHistory,
}) {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  // console.log("🚀 ~ IdeaField ~ msg:", msg);
  const [isEdit, setIsEdit] = useState([]);
  const [currentMsg, setCurrentMsg] = useState(msg.content);

  // let [cacheMsg, setCacheMsg] = useState([]);

  const Plugins = useContext(PluginContext);
  const [isClickEdit, setIsClickEdit] = useState(false);
  const [formulaFiles, setFormulaFiles] = useState();

  useEffect(() => {
    isClickEdit || setCurrentMsg(msg.content);
    // console.log("🚀 ~ useEffect ~ msg?.content:", msg?.content);
    cacheMsg = [];
    if (msg?.content) {
      JSON.parse(JSON.stringify(msg?.content)).forEach((item, index) => {
        // console.log("🚀 ~ JSON.parse ~ item:", item);
        if (!["suggest", "ff"].includes(item.return_type)) item.data = "";

        cacheMsg = [...cacheMsg, item];
      });
    }

    // 如果只返回一段文本，且该文本为ff类型，则不开启打字
    if (msg?.content.length == 1 && msg?.content[0].return_type == "ff") {
      eventBus.$emit("AIStatus", {
        messageInfo: {
          msgId: "",
          isSending: false,
        },
      });
    }
  }, [msg.content]);
  // useEffect(() => {
  //   console.log("🚀 ~ useEffect ~ cacheMsg:", cacheMsg);
  // }, [cacheMsg]);
  // const [resContent, setResContent] = useState(msg?.content);
  const setEditContent = (index, content) => {
    let resC = { content: [] };
    let tempMsg = [...currentMsg];
    tempMsg[index].data = content;
    tempMsg.forEach((item) => {
      resC.content.push({ ...item });
    });
    let resJson = JSON.stringify(resC);
    const data = { id: wholeItem.id, resContent: resJson };
    setIsClickEdit(true);
    // console.log(wholeItem);
    saveDetail(data).then((res) => {
      if (res.code == 0) {
        // console.log("📕--------------", res);

        setCurrentMsg(tempMsg);
      } else {
        message.error(res.msg);
      }
    });

    // console.log(content);
  };
  const compareTexts = (index, item) => {
    // console.log("🚀 ~ compareTexts ~ index:", cacheMsg, index, item);
    if (cacheMsg) {
      cacheMsg[index].data = item;
      // console.log("🚀 ~ compareTexts ~ cacheMsg:", cacheMsg);
      cacheMsg = [...cacheMsg];
    }
  };

  return (
    <div className="chatFlowItem robot" id={sId}>
      {/* {compareTexts()} */}
      <div className="avatar">
        {aiStatus.messageInfo.isSending &&
          aiStatus.messageInfo.msgId === sId && (
            <Spin
              className="loadingAvatar"
              indicator={
                <LoadingOutlined
                  style={{ width: "100%", height: "100%" }}
                  spin
                />
              }
            />
          )}
      </div>
      <div className="chatContent">
        {currentMsg &&
          currentMsg.map((sitem, sindex) => {
            // console.log("🚀 ~ currentMsg.map ~ sitem:", sitem);
            // 外挂组件专用
            const Component = Plugins[sitem?.return_type] || "";
            // mardown 组件专用
            const noMarkdown = [
              "ff",
              "suggest",
              "whole_article",
              "whole_new_article",
              "error",

              ...Object.keys(Plugins),
            ];

            // 如果是(1、非markdown类型,2、推荐类型除外，3、最后一个消息，4、且是在开启了打字）则关闭打字。
            if (
              ["error"].includes(sitem?.return_type) &&
              aiStatus.messageInfo.msgId === sitem?.id &&
              aiStatus.messageInfo.isSending
            ) {
              eventBus.$emit("AIStatus", {
                messageInfo: {
                  msgId: "",
                  isSending: false,
                },
              });
            }
            const markdownView =
              !noMarkdown.includes(sitem?.return_type) || false;

            // 生成配方专用
            const genFormula = sitem?.return_type === "ff" || false;

            // 推荐文本专用
            const suggestView = sitem?.return_type === "suggest" || false;

            // 异常文本专用
            const errorView = sitem?.return_type === "error" || false;

            // console.log(
            //   "🚀 ~ {itemResParse.map ~ item:",
            //   sitem,
            //   Plugins,
            //   Component
            // );
            return (
              <Fragment
                key={sId + sindex + ""}
                // className={
                //   sitem.return_type == "whole_article" ||
                //   sitem.return_type == "whole_new_article"
                //     ? "chatText chatTextNobg"
                //     : sitem.return_type == "new_article"
                //     ? "chatText newArticle"
                //     : "chatText"
                // }
              >
                {/* 处理 动作的组件专用 */}
                {Component && (
                  <div className="chatText">
                    <Component aiStatus={aiStatus} content={sitem.data} />
                  </div>
                )}
                {/* 处理聊天对话的内容 */}
                {!isEdit[sindex] && markdownView && (
                  <div
                    className={
                      sitem.return_type == "whole_article" ||
                      sitem.return_type == "whole_new_article"
                        ? "chatText chatTextNobg"
                        : sitem.return_type == "new_article"
                        ? "chatText newArticle"
                        : "chatText"
                    }
                  >
                    <Markdown
                      compareTexts={compareTexts}
                      sourceData={sitem}
                      sindex={sindex}
                      content={sitem.data}
                      returnType={sitem.return_type}
                      aiStatus={aiStatus}
                      sId={sId}
                    />
                  </div>
                )}
                {/* 编辑内容 */}
                {isEdit[sindex] && markdownView && (
                  <div className="chatText">
                    <MarkdownEditor
                      content={sitem.data}
                      aiStatus={aiStatus}
                      setEditContent={setEditContent}
                      sindex={sindex}
                    />
                  </div>
                )}

                {/* 生成配方 */}
                {genFormula && (
                  <GeneFormulaFile
                    formulaFiles={sitem.data}
                    sendMessage={sendMessage}
                  />
                )}

                {/* 推荐 */}
                {suggestView && (
                  <div className="suggestText">
                    <Button
                      type="primary"
                      ghost
                      style={{ color: "#1677ff" }}
                      onClick={() =>
                        sendMessage({
                          content: `${sitem.data}`,
                          role: "user",
                          contentType: 0,
                          actionType: "chat",
                        })
                      }
                    >
                      {sitem.data}
                      <i className="iconfont icon-jiantou_xiangyou"></i>
                    </Button>
                  </div>
                )}

                {/* 异常 */}
                {errorView && (
                  <div className="chatText">
                    {sitem.data}
                    <i
                      className="iconfont icon-shuaxin1 reload"
                      onClick={() => {
                        let itemReqParse = JSON.parse(
                          wholeItem?.reqContent || "{}"
                        );
                        console.log(
                          "🚀 ~ currentMsg?.map ~ itemReqParse:",
                          itemReqParse
                        );
                        sendMessage(itemReqParse);
                      }}
                    ></i>
                  </div>
                )}

                {/* 处理各对话场景下的不同的功能按钮 */}
                {(sitem?.data ||
                  sitem.return_type == "whole_article" ||
                  sitem.return_type == "whole_new_article") &&
                  Component === "" && (
                    <OperaButtons
                      sindex={sindex}
                      isEdit={isEdit}
                      aiStatus={aiStatus}
                      setIsEdit={setIsEdit}
                      sitem={sitem}
                      currentId={wholeItem.id}
                      sendMessage={sendMessage}
                      currentMsg={currentMsg}
                    />
                  )}
                {/* <Button>创新方法</Button> */}
              </Fragment>
            );
          })}

        {msg?.url && (
          <a href={msg?.url} target="_black" className="link">
            {msg?.url}
          </a>
        )}
        {/* <div className="chatAction">
          <Space>
            <Button  type="primary">
              编辑
            </Button>
            <Button  type="primary">
              下载
            </Button>
          </Space>
        </div> */}
      </div>

      {aiStatus.messageInfo.isSending &&
        aiStatus.messageInfo.msgId === sId &&
        userInfo?.id !== sId && (
          <div
            style={{
              padding: "10px 52px",
              display: "inline-block",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                if (cacheMsg[0].data) {
                  eventBus.$emit("AIStatus", {
                    messageInfo: {
                      writedText: cacheMsg,
                      msgId: "",
                      isSending: false,
                    },
                  });
                } else {
                  eventBus.$emit("AIStatus", {
                    messageInfo: {
                      writedText: false,
                      msgId: "",
                      isSending: false,
                    },
                  });
                }
              }}
            >
              停止生成
            </Button>
          </div>
        )}
    </div>
  );
}
