import { useState, useEffect, useRef } from "react";
import { Col, Row } from "antd";
import "./index.scss";

export default function InitalView({ userInfo, basisData }) {
  // useEffect(() => {
  //   const worker = new Worker(new URL("./worker.js", import.meta.url));

  //   worker.onmessage = function (e) {
  //     setDisplayText((prevText) => prevText + e.data);
  //   };

  //   worker.postMessage(text);

  //   return () => {
  //     worker.terminate();
  //   };
  // }, [text]);
  return (
    <div className="initalView">
      <div className="logo">
        {
          JSON.parse(basisData?.dataValue || '{"aiSystemName": "AI-Lab"}')
            ?.aiSystemName
        }
      </div>

      {/* <div className="decs"> 你的AI科研助手</div> */}
      <div className="chatContent">
        <div className="tipList">
          <div className="tipItem">
            <i
              className="iconfont icon-xiangqing"
              style={{ color: "#1677ff" }}
            ></i>
            <div className="title">输入论文DOI或上传pdf</div>
            <div className="desc">10.1021/acs.macromol.3c02018</div>
          </div>

          <div className="tipItem">
            <i className="iconfont icon-huo"></i>
            <div className="title">说出您感兴趣的研究方向</div>
            <div className="desc">做一个可以在钙钛矿领域发表Nature的实验</div>
          </div>
        </div>
      </div>
    </div>
  );
}
